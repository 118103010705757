<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>审核</a-breadcrumb-item>
              <a-breadcrumb-item>审核中心</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div v-if="visible">
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="!visible"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px; margin-top: 3px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flow-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-if="i == '审批类型'"
                  v-model="params['type'].value"
                  placeholder="请选择审批类型"
                  style="width: 173.5px"
                >
                  <a-select-option value="0"> 铺位调整 </a-select-option>
                  <a-select-option value="1"> 意向合同提交 </a-select-option>
                  <a-select-option value="3"> 核销 </a-select-option>
                  <a-select-option value="21"> 正式合同提交 </a-select-option>
                  <a-select-option value="22"> 正式合同重写 </a-select-option>
                  <a-select-option value="23"> 正式合同作废 </a-select-option>
                  <a-select-option value="24">
                    正式合同变更主体
                  </a-select-option>
                  <a-select-option value="25">
                    正式合同变更条款
                  </a-select-option>
                </a-select>
                <div v-else-if="i == '提交时间'">
                  <a-date-picker
                    placeholder="请输入开始日期"
                    v-model="params['submit_time'].value"
                    style="width: 173.5px"
                  /><span class="a-col-span">-</span>
                  <a-date-picker
                    v-model="params['submit_time#2'].value"
                    style="width: 173.5px"
                    placeholder="请输入结束日期"
                  />
                </div>
                <a-input
                  v-else
                  style="width: 173.5px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div>
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="auditHistorylist"
              :rowClassName="rowClassName"
            >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="operate" slot-scope="text">
                <a-badge
                  :status="text | statusTypeFilter"
                  :text="text | statusFilter"
                />
              </span>
              <span slot="linkType" slot-scope="text">
                {{
                  text == 0
                    ? '铺位调整'
                    : text == 1
                    ? '意向合同提交'
                    : text == 3
                    ? '核销'
                    : text == 21
                    ? '正式合同提交'
                    : text == 22
                    ? '正式合同重写'
                    : text == 23
                    ? '正式合同作废'
                    : text == 24
                    ? '正式合同变更主体'
                    : '正式合同变更条款'
                }}
              </span>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
            <a-modal
              @cancel="onauditClose"
              width="1000px"
              title="审核信息"
              :visible="auditvisible"
            >
              <a-descriptions
                layout="vertical"
                bordered
                v-for="item in historylist"
                :key="item.id"
                :column="7"
              >
                <a-descriptions-item label="审核类型">
                  {{
                    item.linkType == 0
                      ? '铺位调整'
                      : item.linkType == 1
                      ? '意向合同提交'
                      : item.linkType == 3
                      ? '核销'
                      : item.linkType == 21
                      ? '正式合同提交'
                      : item.linkType == 22
                      ? '正式合同重写'
                      : item.linkType == 23
                      ? '正式合同作废'
                      : item.linkType == 24
                      ? '正式合同变更主体'
                      : '正式合同变更条款'
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="审核人">
                  {{ item.auditName }}
                </a-descriptions-item>
                <a-descriptions-item label="审核时间">
                  {{ item.auditTime | time }}
                </a-descriptions-item>
                <a-descriptions-item label="审核备注">
                  {{ item.auditNote }}
                </a-descriptions-item>
                <a-descriptions-item label="审核结果">
                  {{
                    item.operate == 0
                      ? '提交'
                      : item.operate == 1
                      ? '撤回'
                      : item.operate == 2
                      ? '通过'
                      : '拒绝'
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="审核附件" :span="2">
                  <a-upload
                    action=""
                    :file-list="item.auditAttach"
                    @preview="handlePreview"
                  >
                  </a-upload>
                  <a-modal
                    :visible="previewVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img
                      alt="example"
                      style="width: 100%"
                      :src="previewImage"
                    />
                  </a-modal>
                </a-descriptions-item>
              </a-descriptions>
            </a-modal>
          </div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout v-else style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>基础信息</span>
        </div>
        <div style="flex-wrap: wrap" class="content-body flex-d-row"></div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '审批类型',
    dataIndex: 'linkType',
    scopedSlots: { customRender: 'linkType' },
  },
  {
    title: '简介',
    dataIndex: 'description',
  },
  {
    title: '提交人',
    dataIndex: 'submitName',
  },
  {
    title: '提交时间',
    dataIndex: 'submitTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '提交备注',
    dataIndex: 'submitNote',
  },
  {
    title: '审核人',
    dataIndex: 'auditName',
  },
  {
    title: '审核时间',
    dataIndex: 'auditTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '审核结果',
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate' },
  },
  {
    title: '审核备注',
    dataIndex: 'auditNote',
  },
]
const statusMap = {
  0: {
    status: 'warning',
    text: '提交',
  },
  1: {
    status: 'error',
    text: '撤回',
  },
  2: {
    status: 'success',
    text: '通过',
  },
  3: {
    status: 'error',
    text: '拒绝',
  },
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
import http from '../../../http'
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
export default {
  data() {
    return {
      columns,
      visible: false,
      previewVisible: false,
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      previewImage: '',
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      params: {
        current: 1,
        pageSize: 10,
        type: {
          value: undefined,
          op: '=',
        },
        submit_name: {
          value: '',
          op: '%like%',
        },
        submit_time: {
          value: null,
          op: '>=',
        },
        'submit_time#2': {
          value: null,
          op: '<=',
        },
      },
      total: 0,
      textlist: ['审批类型', '提交人', '提交时间'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      Id: '',
      auditAttach: [],
      historylist: [],
      form: {
        id: '',
        auditNote: '',
        auditAttach: '',
        operate: '',
      },
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    },
    text(value) {
      if (!value) return ''
      if (value.length > 12) {
        return value.slice(0, 12) + '...'
      }
      return value
    },
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    handleSearch(e) {
      this.params.current = 1
      e.preventDefault()
      this.fom.validateFields((error, values) => {
        this.params['submit_name'].value = values.提交人
        // console.log(values);
      })
      this.getsearchHistory()
    },
    handleReset() {
      this.fom.resetFields()
      this.params['submit_time'].value = undefined
      this.params['submit_time#2'].value = undefined
      this.params['type'].value = undefined
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      //   console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      if (
        file.type == 'jpeg' ||
        file.type == 'png' ||
        file.type == 'jpg' ||
        file.type == 'gif' ||
        file.type == 'tiff'
      ) {
        this.previewImage = file.url || file.preview
        this.previewVisible = true
      } else {
        window.open(file.url)
      }
    },
    //提交
    onsubmit(e) {
      this.form.operate = e
      //   console.log(this.form);
      this.getauditaudit(this.form)
    },
    // 编辑
    onchangestate(e) {
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.stateModule(e.id, this.form.state)
    },
    async getauditaudit(form) {
      try {
        const res = await http.getauditaudit(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success('提交成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onpagesize(e) {
      this.params.current = e
      setTimeout(() => {
        this.getsearchHistory()
      }, 100)
    },
    onEdit(e) {
      this.auditvisible = true
      this.getauditCenterrecord(e.id)
    },
    onauditClose() {
      this.auditvisible = false
      this.historylist = []
    },
    async getsearchHistory() {
      try {
        const res = await http.getsearchHistory(this.params)
        const { success, data } = res.data
        if (success) {
          this.auditHistorylist = data.audits.records
          this.total = data.audits.total
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getauditCenterrecord(id) {
      try {
        const res = await http.getauditCenterrecord(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          if (data.history.length < 0) {
            return
          }
          let url
          let a = []
          data.history.forEach(item => {
            if (item.auditAttach == null) {
              return
            } else {
              JSON.parse(item.auditAttach).forEach(item => {
                url = client.signatureUrl(item.name)
                a.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  type: item.type,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              item.auditAttach = a
            }
          })
          this.historylist = data.history
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },

    timer() {
      return setTimeout(() => {
        this.getsearchHistory()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
  },
  mounted() {
    this.getsearchHistory()
    this.getsts()
  },
  computed: {},
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flow-d-row {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  padding-left: 10px;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.form-item {
  .ant-form-item {
    // height: 60px;
    margin-bottom: 0;
  }
}
.ant-form-item {
  line-height: 2.5;
  margin: 0;
}
::v-deep .ant-form-item-label {
  line-height: 20px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 600;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.ant--input {
  width: 50%;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 25px;
}
</style>
